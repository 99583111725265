import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/Helpers';
import Tooltip from 'components/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const propTypes = {
  paymentPlans: PropTypes.objectOf(PropTypes.number),
  installmentsMinAmount: PropTypes.number.isRequired,
};

const InstallmentsTooltip = ({ paymentPlans, installmentsMinAmount }) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');

  const { availableBanks } = env.installments;
  if (!features.INSTALLMENTS_ENABLED || !paymentPlans) return null;

  const maxInstallments = Math.max(...Object.keys(paymentPlans));
  const displayPrice = Math.min(...Object.values(paymentPlans));

  const months = Object.keys(paymentPlans);
  const last = months.length - 1;
  const displayMonths = `${months.slice(0, last).join(', ')} y ${months[last]}`;
  const paymentPlansAvailable = maxInstallments > 0;
  const tooltipCopyMaxInstallments = paymentPlansAvailable ? maxInstallments : 12;
  const tooltipTitle = paymentPlansAvailable
    ? 'Meses con interés fijos con los siguientes bancos'
    : 'Pagos fijos';
  const tooltipParapraph = paymentPlansAvailable
    ? `Ahora podrás diferir tus compras a partir de ${formatCurrency(
        displayPrice,
      )} en ${displayMonths} a meses
      con interés fijo incluido con los siguientes bancos:`
    : `en compras mayores a ${formatCurrency(installmentsMinAmount)} ${t('currency')}, con
      tarjetas de bancos participantes`;

  return (
    <Tooltip className="installments-tooltip">
      <span className="installments-tooltip-copy">
        <i className="installments-tooltip-icon" /> Hasta {tooltipCopyMaxInstallments} pagos fijos
      </span>

      <div className="tooltip">
        <div className="tooltip-header">
          <p className="tooltip-title">{tooltipTitle}</p>

          <p className="tooltip-paragraph">{tooltipParapraph}</p>
        </div>

        <div className="tooltip-content">
          <div className="grid grid-cols-6 gap-4">
            {availableBanks.map((bankName) => (
              <div key={bankName} className="flex items-center justify-center bank-icon">
                <i className={`bank-${bankName}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

InstallmentsTooltip.propTypes = propTypes;

InstallmentsTooltip.defaultProps = {
  paymentPlans: [],
};

export default InstallmentsTooltip;
